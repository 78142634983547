@use "sass:list";
@use "sass:math";

// PATHS
$img-path: '../img' !default;
$font-path: '../fonts' !default;

// ----------- //

// COLORS
$black: #000;
$white: #FFF;
$red: #ca1e1e;
$red2: #d7073d;
$red3: #f8d7da;
$green: #008e49;
$green2: #01aa58;
$green3: #1bcd77;
$blue: #004997;
$blue2: #1fb6f5;
$gray: #edefef;
$gray2: #ced3d3;
$gray3: #bfc4c4;
$gray4: #9ba3a3;

$primary: $black;
$secondary: #31363d;

// ----------- //

// DEFAULT FONT
$base-font: 'Montserrat', sans-serif;
$base-size: 16;
$font-size: #{$base-size}px;

// ----------- //

$navbar-sm: 60px;
$navbar-lg: 73px;

// ----------- //

$transition: all .25s ease;

// ----------- //
//   @MIXINS   //
// ----------- //

// transition speed
@mixin transition($value, $property: null) {
	@if $property == null {
		transition: all #{$value}s ease;
	}
	@else if $property != null {
		transition: $property #{$value}s ease;
	}
}

// ----------- //

// font-size: PX to REM
@mixin font-size($value) {
	font-size: #{$value}px;
	font-size: #{math.div($value, $base-size)}rem;
}

// ----------- //

// property: PX to REM;
@function strip-unit($num) {
	@return $num / ($num * 0 + 1);
}
@mixin rem($property, $values...) {
	$max: length($values);
	$pxValues: '';
	$remValues: '';

	@for $i from 1 through $max {
		$value: strip-unit(nth($values, $i));
		$pxValues: #{$pxValues + $value}px;

		@if $i < $max {
			$pxValues: #{$pxValues + " "};
		}
	}
	@for $i from 1 through $max {
		$value: strip-unit(nth($values, $i));
		$remValues: #{$remValues + $value/$base-size}rem;

		@if $i < $max {
			$remValues: #{$remValues + " "};
		}
	} 

	#{$property}: $pxValues; 
	#{$property}: $remValues; 
}

// ----------- //

// BREAKPOINTS
$landscape: landscape;
$portrait: portrait;

@mixin breakpoint($point, $orientation: null) {
	@if $orientation == null {
		@media screen and (min-width: #{$point}px) {@content;}
	}
	@else if $orientation != null {
		@media screen and (min-width: #{$point}px) and (orientation: $orientation) {@content;}
	}
}
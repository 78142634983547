@import "_variables";


/* MESSAGE WALL */
#psn-wall {
	padding: 0 15px;

	.container {max-width: 1200px;}

	//.wall-wrapper {height: 100%;}

	#content {order: 2;}

	#sidebar {
		margin-bottom: 0;
		order: 1;

		.content-block {padding: 15px;}

		.today {
			background: $white;
			padding: 0 15px;
		}

		.list-employees strong {border-color: $white;}

		//.gallery {margin-bottom: 17px;}
	}

	// spolocne pre content + sidebar
	.list-employees {
		margin-bottom: -1px;

		.item {
			border-color: transparent;
			width: 100%;
			padding: 0 0 14px;

			&.hidden {display: none;}
		}

		.thumb {
			border: 3px solid $white;
			width: 70px;
			height: 70px;
		}

		.caption {
			width: calc(100% - 83px);
			padding-left: 19px;
			line-height: 1.2;
		}

		strong {
			border-bottom: 1px solid $gray;
			margin-bottom: 4px;
			padding-bottom: 2px;
		}
	}

	.content-block {
		border-radius: 4px;
		background: $gray;
		margin-bottom: 20px;
		padding: 0;
		font-size: 1rem;

		h2 {margin-bottom: 26px;}

		> a {
			text-transform: uppercase;
			font-weight: 700;
			font-size: 13px;
		}
	}

	.new-post {
		border-radius: 4px;
		background: $gray;
		margin-bottom: 20px;

		summary {
			min-height: 63px;
			padding: 9px 15px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-weight: bold;
		}

		textarea {width: 100%;}
	}

	.gallery {
		display: grid;
		grid-gap: 1px;
		grid-template-columns: repeat(2, 1fr);
		margin-bottom: 17px;

		&[data-columns="1"] {grid-template-columns: repeat(1, 1fr);}

		.gallery-item {
			position: relative;
			overflow: hidden;

			&:hover {
				img {transform: scale(1.05);}
			}
		}

		.caption {
			background: rgba($black, .52);
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 52px;
			color: $white;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
		}

		img,
		video {
			width: 100%;
			height: 100%;
			aspect-ratio: 1 / .73;
			object-fit: cover;
			object-position: center;
			transition: all .75s ease;
		}
	}

	.show-more,
	.show-less {
		padding-right: 18px;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 13px;
		position: relative;

		&:after {
			content: "";
			width: 0; 
			height: 0; 
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 7px solid $green;
			position: absolute;
			top: 5px;
			right: 0;
		}
	}
	.show-less {
		&:after {transform: scale(-1);}
	}

	[data-bs-toggle="popover"] {cursor: pointer;}
	.popover {
		text-align: center;

		.popover-body {padding: 5px 15px;}
	}


	@include breakpoint(568){
		#sidebar .gallery {grid-template-columns: repeat(3, 1fr);}
	}
	@include breakpoint(1024){
		#content {
			padding-right: 20px;
			order: 1;
		}

		#sidebar {
			max-width: 466px;
			order: 2;

			.content-block {padding: 27px;}

			.today {
				min-height: 63px;
				padding: 22px 27px;
			}

			.gallery {grid-template-columns: repeat(2, 1fr);}

			.list-employees {
				.thumb {
					width: 83px;
					height: 83px;
				}
			}
		}

		.new-post summary {padding: 22px 27px;}
	}
}


.wall-post {
	border-radius: 4px;
	background: $gray;
	margin-bottom: 20px;
	padding: 0 2px;
	font-size: 1rem;
	font-weight: normal;

	.post-header {
		padding: 9px 15px;
		overflow: hidden;

		.post-owner {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			margin-right: 0;

			.thumb {
				width: 45px;
				height: 45px;
				margin-right: 8px;
			}
		}

		.post-date {font-weight: normal;}

		.d-flex {float: right;}
	}

	.post-body {
		background: $white;
		margin: 0;
		padding: 15px 15px 0;

		p {margin-bottom: 16px;}

		.img-fluid {width: 100%;}

		.gallery {
			margin-bottom: 3px;

			.gallery-item:nth-child(n+5) {display: none;}
		}
	}

	.post-rating {
		min-height: 64px;
		padding: 10px 0;
		position: relative;

		> div {
			height: 100%;
			padding: 5px 0;
		}

		span:not(.icons) {padding-bottom: 3px;}

		.icons {
			display: inline-flex;
			align-items: flex-end;
			margin-right: 15px;
			font-family: "Twemoji Mozilla","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji","EmojiOne Color","Android Emoji",sans-serif;

			span {
				margin: -5px -15px 0 0;
				padding: 0 !important;
				font-size: 20px;
			}
		}

		.comments {
			padding-right: 32px;
			position: relative;

			&:after {
				content: "";
				background: url("#{$img-path}/icons/bubble.svg") 0 0 no-repeat;
				background-size: 100%;
				width: 22px;
				height: 19px;
				position: absolute;
				top: 0;
				right: 0;
			}
		}
	}

	.post-comments {
		padding: 15px;
		font-size: 14px;

		.hidden {display: none;}

		.show-more,
		.show-less {
			display: inline-block;
			margin-top: 9px;
		}
	}

	.comment-form {
		display: flex;
		position: relative;

		.position-relative {
			flex: 1;
			margin-top: 4px;
		}

		.form-control {
			border: 0;
			height: auto;
			min-height: 36px;
			max-height: 200px;
			margin: 0;
			padding: 7px 60px 5px 23px;
			font-family: "Montserrat","Twemoji Mozilla","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji","EmojiOne Color","Android Emoji", sans-serif;
		}

		.comment-controls {
			height: 36px;
			display: flex;
			align-items: center;
			position: absolute;
			top: 0px;
			right: 11px;
		}

		.e-picker {
			display: inline-block;
			background: url("#{$img-path}/icons/emoji.svg") center no-repeat;
			background-size: 100%;
			width: 19px;
			height: 18px;
			margin-right: 9px;
		}

		.submit-button {
			display: inline-block;
			border: 0;
			background: url("#{$img-path}/icons/submit.svg") center no-repeat;
			background-size: 100%;
			width: 14px;
			height: 16px;
			padding: 0;
		}
	}

	.comments-wrapper {
		max-height: 500px;
		margin-top: 20px;
		position: relative;
		overflow: auto;
	}

	.wall-comment {
		display: flex;
		padding-bottom: 9px;
		transition: background cubic-bezier(0, 0.25, 1, 1) 1s;

		&.highlighted {
			background: rgba($gray2, .4);
			transition-duration: .2s;
		}

		+ .wall-comment {
			border-top: 1px solid $white;
			padding-top: 9px;
		}

		.comment-body {
			flex: 1;
			padding-top: 2px;

			.date {margin-bottom: 3px;}
			.reply-to {cursor: pointer;}
			//.cancel {color: $red;}

			p {line-height: 1.15;}

			form {margin-top: 10px;}
		}
	}

	.post-owner {
		margin-right: 10px;
		font-weight: bold;

		.thumb {
			border-radius: 50%;
			border: 2px solid $white;
			background: $gray;
			width: 42px;
			height: 42px;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: top;
			}
		}
	}

	.cancel,
	.comment-delete,
	.post-delete {color: $red;}

	.post-edit,
	.post-delete {
		border-radius: 50%;
		width: 30px;
		height: 30px;
		display: inline-flex !important;
		align-items: center;
		justify-content: center;
		margin-left: 10px;
		text-decoration: none;
	}
	.post-edit {
		background: $green;
		font-size: 13px;
		color: $white;
	}
	.post-delete {
		background: $red;
		font-size: 2.5rem;
		color: $white;
	}


	@include breakpoint(360){
		.post-header {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.d-flex {float: none;}
		}
	}
	@include breakpoint(568){
		.post-rating {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 15px 0;

			> div {
				display: inline-flex;
				padding: 0;
			}

			span:not(.icons) {padding-bottom: 5px;}
		}
	}
	@include breakpoint(720){
		.post-rating {
			.icons {
				margin-right: 5px;

				span {margin: -5px 0 0;}
			}
		}

	}
	@include breakpoint(1200){
		.post-header {padding: 9px 29px 9px 25px;}
		.post-body {padding: 15px 28px 0;}

		.post-rating {
			.icons {
				margin-right: 0;

				span {margin: -5px 5px 0 0;}
			}
		}

		.post-comments {padding: 19px 28px 20px;}

		.post-owner {margin-right: 19px;}
	}
}

/* ------ */

// reaction popover
.react-popover {
	&.show {
		.popover-body .icon {transform: scale(1);}
	}

	.popover-body {
		display: flex;
		justify-content: space-between;

		.icon {
			font-family: "Twemoji Mozilla","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji","EmojiOne Color","Android Emoji",sans-serif;
			font-size: 26px;
			transform: scale(0);
			transition: $transition;
			position: relative;
			top: 0;
			cursor: pointer;

			&:nth-of-type(2) {transition-delay: .03s;}
			&:nth-of-type(3) {transition-delay: .09s;}
			&:nth-of-type(4) {transition-delay: .12s;}
			&:nth-of-type(5) {transition-delay: .15s;}
			&:nth-of-type(6) {transition-delay: .18s;}

			&:hover {top: -5px;}
		}
	}
}

// emoji popover
.emoji-popover {
	max-width: 400px;

	.popover-arrow {z-index: 9;}
	.popover-body {padding: 0 !important;}
}

emoji-picker {
	--border-color: #{$gray2};
	border-radius: 10px;
	border-bottom: 0;
	width: calc(100% + 2px);
	margin: -1px;
	overflow: hidden;

	.tabpanel {
		background: red !important;
	}

	@media screen and (max-width: 320px) {
		--num-columns: 6;
    	--category-emoji-size: 1.125rem;
    	--emoji-padding: .45rem;
		--emoji-size: 1.25rem;
	}
}

/* ------ */

.clone-templates {
	display: none;
}

// custom scrollbar
.css-bar {
	scrollbar-width: thin;
	scrollbar-color: $green $gray2;

	&::-webkit-scrollbar {
		width: 6px;
		height: 8px;
	}
	&::-webkit-scrollbar-track {background-color: $gray2;}
	&::-webkit-scrollbar-thumb {background-color: $green2;}
	&::-webkit-scrollbar-thumb:hover,
	&::-webkit-scrollbar-thumb:active {background-color: $green;}
}

/* ------ */

#psn-wall .list-projects {
	margin: 0;
	padding: 0;
	list-style: none;

	.item {
		border-color: transparent;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: 20px;
		overflow: hidden;
	}

	.thumb {
		border-radius: 6px;
		border: 3px solid $white;
		width: 86px;
		height: 86px;
		overflow: hidden;

		img {
			border-radius: 3px;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: top;
		}
	}

	.caption {
		width: calc(100% - 86px);
		padding-left: 19px;
		line-height: 1.2;
	}

	strong {display: block;}

	a {
		display: inline-block;
		margin: 3px 0 11px;
		font-size: 14px;
	}

	.progress {
		border-radius: 0;
		background: $white; //rgba($gray2, .5);
		height: 13px;
		margin-bottom: 4px;

		.units-sold {background: $green;}
		.units-reserved {background: $blue2;}
		.units-free {background: $white;}
	}
}








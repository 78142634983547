/* MESSAGE WALL */
#psn-wall {
  padding: 0 15px;
}
#psn-wall .container {
  max-width: 1200px;
}
#psn-wall #content {
  order: 2;
}
#psn-wall #sidebar {
  margin-bottom: 0;
  order: 1;
}
#psn-wall #sidebar .content-block {
  padding: 15px;
}
#psn-wall #sidebar .today {
  background: #FFF;
  padding: 0 15px;
}
#psn-wall #sidebar .list-employees strong {
  border-color: #FFF;
}
#psn-wall .list-employees {
  margin-bottom: -1px;
}
#psn-wall .list-employees .item {
  border-color: transparent;
  width: 100%;
  padding: 0 0 14px;
}
#psn-wall .list-employees .item.hidden {
  display: none;
}
#psn-wall .list-employees .thumb {
  border: 3px solid #FFF;
  width: 70px;
  height: 70px;
}
#psn-wall .list-employees .caption {
  width: calc(100% - 83px);
  padding-left: 19px;
  line-height: 1.2;
}
#psn-wall .list-employees strong {
  border-bottom: 1px solid #edefef;
  margin-bottom: 4px;
  padding-bottom: 2px;
}
#psn-wall .content-block {
  border-radius: 4px;
  background: #edefef;
  margin-bottom: 20px;
  padding: 0;
  font-size: 1rem;
}
#psn-wall .content-block h2 {
  margin-bottom: 26px;
}
#psn-wall .content-block > a {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
}
#psn-wall .new-post {
  border-radius: 4px;
  background: #edefef;
  margin-bottom: 20px;
}
#psn-wall .new-post summary {
  min-height: 63px;
  padding: 9px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
}
#psn-wall .new-post textarea {
  width: 100%;
}
#psn-wall .gallery {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 17px;
}
#psn-wall .gallery[data-columns="1"] {
  grid-template-columns: repeat(1, 1fr);
}
#psn-wall .gallery .gallery-item {
  position: relative;
  overflow: hidden;
}
#psn-wall .gallery .gallery-item:hover img {
  transform: scale(1.05);
}
#psn-wall .gallery .caption {
  background: rgba(0, 0, 0, 0.52);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 52px;
  color: #FFF;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
#psn-wall .gallery img,
#psn-wall .gallery video {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/0.73;
  object-fit: cover;
  object-position: center;
  transition: all 0.75s ease;
}
#psn-wall .show-more,
#psn-wall .show-less {
  padding-right: 18px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  position: relative;
}
#psn-wall .show-more:after,
#psn-wall .show-less:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 7px solid #008e49;
  position: absolute;
  top: 5px;
  right: 0;
}
#psn-wall .show-less:after {
  transform: scale(-1);
}
#psn-wall [data-bs-toggle=popover] {
  cursor: pointer;
}
#psn-wall .popover {
  text-align: center;
}
#psn-wall .popover .popover-body {
  padding: 5px 15px;
}
@media screen and (min-width: 568px) {
  #psn-wall #sidebar .gallery {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 1024px) {
  #psn-wall #content {
    padding-right: 20px;
    order: 1;
  }
  #psn-wall #sidebar {
    max-width: 466px;
    order: 2;
  }
  #psn-wall #sidebar .content-block {
    padding: 27px;
  }
  #psn-wall #sidebar .today {
    min-height: 63px;
    padding: 22px 27px;
  }
  #psn-wall #sidebar .gallery {
    grid-template-columns: repeat(2, 1fr);
  }
  #psn-wall #sidebar .list-employees .thumb {
    width: 83px;
    height: 83px;
  }
  #psn-wall .new-post summary {
    padding: 22px 27px;
  }
}

.wall-post {
  border-radius: 4px;
  background: #edefef;
  margin-bottom: 20px;
  padding: 0 2px;
  font-size: 1rem;
  font-weight: normal;
}
.wall-post .post-header {
  padding: 9px 15px;
  overflow: hidden;
}
.wall-post .post-header .post-owner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 0;
}
.wall-post .post-header .post-owner .thumb {
  width: 45px;
  height: 45px;
  margin-right: 8px;
}
.wall-post .post-header .post-date {
  font-weight: normal;
}
.wall-post .post-header .d-flex {
  float: right;
}
.wall-post .post-body {
  background: #FFF;
  margin: 0;
  padding: 15px 15px 0;
}
.wall-post .post-body p {
  margin-bottom: 16px;
}
.wall-post .post-body .img-fluid {
  width: 100%;
}
.wall-post .post-body .gallery {
  margin-bottom: 3px;
}
.wall-post .post-body .gallery .gallery-item:nth-child(n+5) {
  display: none;
}
.wall-post .post-rating {
  min-height: 64px;
  padding: 10px 0;
  position: relative;
}
.wall-post .post-rating > div {
  height: 100%;
  padding: 5px 0;
}
.wall-post .post-rating span:not(.icons) {
  padding-bottom: 3px;
}
.wall-post .post-rating .icons {
  display: inline-flex;
  align-items: flex-end;
  margin-right: 15px;
  font-family: "Twemoji Mozilla", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", "EmojiOne Color", "Android Emoji", sans-serif;
}
.wall-post .post-rating .icons span {
  margin: -5px -15px 0 0;
  padding: 0 !important;
  font-size: 20px;
}
.wall-post .post-rating .comments {
  padding-right: 32px;
  position: relative;
}
.wall-post .post-rating .comments:after {
  content: "";
  background: url("../img/icons/bubble.svg") 0 0 no-repeat;
  background-size: 100%;
  width: 22px;
  height: 19px;
  position: absolute;
  top: 0;
  right: 0;
}
.wall-post .post-comments {
  padding: 15px;
  font-size: 14px;
}
.wall-post .post-comments .hidden {
  display: none;
}
.wall-post .post-comments .show-more,
.wall-post .post-comments .show-less {
  display: inline-block;
  margin-top: 9px;
}
.wall-post .comment-form {
  display: flex;
  position: relative;
}
.wall-post .comment-form .position-relative {
  flex: 1;
  margin-top: 4px;
}
.wall-post .comment-form .form-control {
  border: 0;
  height: auto;
  min-height: 36px;
  max-height: 200px;
  margin: 0;
  padding: 7px 60px 5px 23px;
  font-family: "Montserrat", "Twemoji Mozilla", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", "EmojiOne Color", "Android Emoji", sans-serif;
}
.wall-post .comment-form .comment-controls {
  height: 36px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0px;
  right: 11px;
}
.wall-post .comment-form .e-picker {
  display: inline-block;
  background: url("../img/icons/emoji.svg") center no-repeat;
  background-size: 100%;
  width: 19px;
  height: 18px;
  margin-right: 9px;
}
.wall-post .comment-form .submit-button {
  display: inline-block;
  border: 0;
  background: url("../img/icons/submit.svg") center no-repeat;
  background-size: 100%;
  width: 14px;
  height: 16px;
  padding: 0;
}
.wall-post .comments-wrapper {
  max-height: 500px;
  margin-top: 20px;
  position: relative;
  overflow: auto;
}
.wall-post .wall-comment {
  display: flex;
  padding-bottom: 9px;
  transition: background cubic-bezier(0, 0.25, 1, 1) 1s;
}
.wall-post .wall-comment.highlighted {
  background: rgba(206, 211, 211, 0.4);
  transition-duration: 0.2s;
}
.wall-post .wall-comment + .wall-comment {
  border-top: 1px solid #FFF;
  padding-top: 9px;
}
.wall-post .wall-comment .comment-body {
  flex: 1;
  padding-top: 2px;
}
.wall-post .wall-comment .comment-body .date {
  margin-bottom: 3px;
}
.wall-post .wall-comment .comment-body .reply-to {
  cursor: pointer;
}
.wall-post .wall-comment .comment-body p {
  line-height: 1.15;
}
.wall-post .wall-comment .comment-body form {
  margin-top: 10px;
}
.wall-post .post-owner {
  margin-right: 10px;
  font-weight: bold;
}
.wall-post .post-owner .thumb {
  border-radius: 50%;
  border: 2px solid #FFF;
  background: #edefef;
  width: 42px;
  height: 42px;
  overflow: hidden;
}
.wall-post .post-owner .thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}
.wall-post .cancel,
.wall-post .comment-delete,
.wall-post .post-delete {
  color: #ca1e1e;
}
.wall-post .post-edit,
.wall-post .post-delete {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  text-decoration: none;
}
.wall-post .post-edit {
  background: #008e49;
  font-size: 13px;
  color: #FFF;
}
.wall-post .post-delete {
  background: #ca1e1e;
  font-size: 2.5rem;
  color: #FFF;
}
@media screen and (min-width: 360px) {
  .wall-post .post-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .wall-post .post-header .d-flex {
    float: none;
  }
}
@media screen and (min-width: 568px) {
  .wall-post .post-rating {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
  }
  .wall-post .post-rating > div {
    display: inline-flex;
    padding: 0;
  }
  .wall-post .post-rating span:not(.icons) {
    padding-bottom: 5px;
  }
}
@media screen and (min-width: 720px) {
  .wall-post .post-rating .icons {
    margin-right: 5px;
  }
  .wall-post .post-rating .icons span {
    margin: -5px 0 0;
  }
}
@media screen and (min-width: 1200px) {
  .wall-post .post-header {
    padding: 9px 29px 9px 25px;
  }
  .wall-post .post-body {
    padding: 15px 28px 0;
  }
  .wall-post .post-rating .icons {
    margin-right: 0;
  }
  .wall-post .post-rating .icons span {
    margin: -5px 5px 0 0;
  }
  .wall-post .post-comments {
    padding: 19px 28px 20px;
  }
  .wall-post .post-owner {
    margin-right: 19px;
  }
}

/* ------ */
.react-popover.show .popover-body .icon {
  transform: scale(1);
}
.react-popover .popover-body {
  display: flex;
  justify-content: space-between;
}
.react-popover .popover-body .icon {
  font-family: "Twemoji Mozilla", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", "EmojiOne Color", "Android Emoji", sans-serif;
  font-size: 26px;
  transform: scale(0);
  transition: all 0.25s ease;
  position: relative;
  top: 0;
  cursor: pointer;
}
.react-popover .popover-body .icon:nth-of-type(2) {
  transition-delay: 0.03s;
}
.react-popover .popover-body .icon:nth-of-type(3) {
  transition-delay: 0.09s;
}
.react-popover .popover-body .icon:nth-of-type(4) {
  transition-delay: 0.12s;
}
.react-popover .popover-body .icon:nth-of-type(5) {
  transition-delay: 0.15s;
}
.react-popover .popover-body .icon:nth-of-type(6) {
  transition-delay: 0.18s;
}
.react-popover .popover-body .icon:hover {
  top: -5px;
}

.emoji-popover {
  max-width: 400px;
}
.emoji-popover .popover-arrow {
  z-index: 9;
}
.emoji-popover .popover-body {
  padding: 0 !important;
}

emoji-picker {
  --border-color: #ced3d3;
  border-radius: 10px;
  border-bottom: 0;
  width: calc(100% + 2px);
  margin: -1px;
  overflow: hidden;
}
emoji-picker .tabpanel {
  background: red !important;
}
@media screen and (max-width: 320px) {
  emoji-picker {
    --num-columns: 6;
    --category-emoji-size: 1.125rem;
    --emoji-padding: .45rem;
    --emoji-size: 1.25rem;
  }
}

/* ------ */
.clone-templates {
  display: none;
}

.css-bar {
  scrollbar-width: thin;
  scrollbar-color: #008e49 #ced3d3;
}
.css-bar::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}
.css-bar::-webkit-scrollbar-track {
  background-color: #ced3d3;
}
.css-bar::-webkit-scrollbar-thumb {
  background-color: #01aa58;
}
.css-bar::-webkit-scrollbar-thumb:hover, .css-bar::-webkit-scrollbar-thumb:active {
  background-color: #008e49;
}

/* ------ */
#psn-wall .list-projects {
  margin: 0;
  padding: 0;
  list-style: none;
}
#psn-wall .list-projects .item {
  border-color: transparent;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  overflow: hidden;
}
#psn-wall .list-projects .thumb {
  border-radius: 6px;
  border: 3px solid #FFF;
  width: 86px;
  height: 86px;
  overflow: hidden;
}
#psn-wall .list-projects .thumb img {
  border-radius: 3px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}
#psn-wall .list-projects .caption {
  width: calc(100% - 86px);
  padding-left: 19px;
  line-height: 1.2;
}
#psn-wall .list-projects strong {
  display: block;
}
#psn-wall .list-projects a {
  display: inline-block;
  margin: 3px 0 11px;
  font-size: 14px;
}
#psn-wall .list-projects .progress {
  border-radius: 0;
  background: #FFF;
  height: 13px;
  margin-bottom: 4px;
}
#psn-wall .list-projects .progress .units-sold {
  background: #008e49;
}
#psn-wall .list-projects .progress .units-reserved {
  background: #1fb6f5;
}
#psn-wall .list-projects .progress .units-free {
  background: #FFF;
}